<template>
    <b-card class="shadow">
        <b-row>
            <b-col cols="12" md="12" lg="9" xl="9">
                <b-media>
                    <template #aside>
                        <img :src="getPictureSrc" class="cart-img" alt="product">
                    </template>
                    <b-card-sub-title>{{getOrganisation.name}}</b-card-sub-title>
                    <b-card-title>{{getName}}</b-card-title>
                    <p class="mb-sm-1 mb-md-3 mb-lg-3">{{getSaleString}}</p>
                    <!-- button remove -->
                    <b-button
                        variant="link"
                        class="text-danger pl-0"
                        size="sm"
                        @click="removeFromCart()"
                    >
                        {{$t('form.remove')}}
                    </b-button>
                </b-media>
            </b-col>
            <!-- quantity, price -->
            <b-col cols="12" md="12" lg="3" xl="3">
                <b-row class="mt-md-3">
                    <!-- price -->
                    <b-col cols="4" md="4" lg="12" xl="12"
                           class="text-center mt-sm-1 mt-md-1 text-black-50">
                        {{getPriceString}}
                    </b-col>
                    <!-- quantity -->
                    <b-col cols="4" md="4" lg="12" xl="12" class="text-center mb-lg-2 mt-lg-1">
                        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm" spinner-small
                                   spinner-variant="secondary">
                            <b-form-spinbutton
                                size="sm"
                                placeholder="0"
                                min="1"
                                max="1000"
                                class="mx-auto"
                                style="max-width:130px"
                                v-model="quantity"
                                @change="updateQuantity()"
                            ></b-form-spinbutton>
                        </b-overlay>
                    </b-col>
                    <!-- sumPrice -->
                    <b-col cols="4" md="4" lg="12" xl="12" class="text-center mt-sm-1 mt-md-1 font-weight-bold h5">
                        {{getSumPrice.string}}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    name: "CartProduct",
    components: {},
    props: {
        uri: {
            type: Object,
            default: {}
        },
        record: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tenant: null,
            quantity: 0,
        }
    },
    mounted() {
        this.tenant = this.__getTenantCookieValue();
        this.quantity = this.record.quantity;
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        removeFromCart() {
            const h = this.$createElement;
            const messageHTML = h('div', [
                h('span', [
                    `${this.$t('cart.delete_message.pre')} `,
                    h('strong', this.getName), this.$t('cart.delete_message.post'),
                ])
            ]);
            // <-- message box -->
            this.$bvModal.msgBoxConfirm([messageHTML], {
                title: this.$t('form.please_confirm'),
                // size: 'sm',
                // buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('form.remove'),
                cancelTitle: this.$t('form.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.$emit('eventRemove', this.record.id);
                    }
                })
                .catch(err => {
                    // on error executed
                })
        },
        updateQuantity() {
            this.showOverlay(true);
            // <-- data -->
            let data = {
                id: this.record.id,
                quantity: this.quantity,
            };
            // <-- axios -->
            this.axios.post(this.uri.uri1 + `/update_quantity`, data, this.__config)
                .then((response) => {
                    this.quantity = response.data.quantity
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        getPictureSrc: function () {
            if (this.__isNull(this.record.pictures_order) || this.record.pictures_order.length === 0 || this.record.pictures_order[0] === undefined) {
                return this.__getDefaultImage;
            }
            // <-- key -->
            let key = null;
            this.record.pictures_order.forEach((value, index) => {
                if (key === null && value.publish) {
                    key = value.key;
                }
            });

            if (key === null) {
                return null;
            }
            // <-- src -->
            let src = null;
            this.record.pictures.find((picture) => {
                if (picture.key === key) {
                    src = picture.image.src;
                }
            });
            // <--  -->
            return this.__getImageSrc(src);
        },
        getOrganisation() {
            if (this.__isNull(this.record.organisation)) {
                return null;
            }
            // <--  -->
            let org = this.record.organisation;

            let name = this.__isNull(org.name.name) ? org.name.full_name : org.name.name;
            // let value = this.__isNull(this.record.name.name) ? this.record.name.full_name : this.record.name.name;
            // <--  -->
            return {
                id: org.id,
                name: name,
            }
        },
        getName() {
            if (this.__isNull(this.record)) {
                return null;
            }
            // <--  -->
            let _record = this.record;
            // <--  -->
            let name = _record.meta.name;
            name = this.__isNull(_record.locale_name) ? name : _record.locale_name;
            // let localeTemp = this.__getJsonLocaleValue(_record.locale, 'name')   // TODO delete deprecated
            // name = localeTemp === null ? name : localeTemp;
            // <--  -->
            return name;
        },
        getSaleString() {
            let sale = this.record.sale;
            // <--  -->
            let packaging = sale.packaging.meta.name;
            let localeTemp = this.__getJsonLocaleValue(sale.packaging.locale, 'name');
            packaging = localeTemp === null ? name : localeTemp;
            // packaging = packaging.toLowerCase();
            // <--  -->
            let string = this.__getDecimalLocale(sale.quantity);
            string += ' ' + sale.unit.meta.mark;
            string += ' ' + packaging;
            // <--  -->
            return string;
        },
        getPrice() {
            if (this.__isNull(this.tenant)) {
                return null;
            }
            // <--  -->
            let sale = this.record.sale;
            // <--  -->
            return this.tenant.taxpayer ? sale.rpt : sale.rp;
        },
        getPriceString() {
            return this.__getCurrencyLocale(this.getPrice);
        },
        getSumPrice() {
            let sumPrice = this.getPrice * this.quantity;
            // <--  -->
            this.$set(this.record, 'sumPrice', sumPrice);
            // <--  -->
            return {
                value: sumPrice,
                string: this.__getCurrencyLocale(sumPrice)
            }
        },
    },
    filters: {},
    watch: {
        'record.quantity': {
            handler: function (value, oldValue) {
                this.quantity = value;
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
