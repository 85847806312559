<template>
    <div class="mb-3">
        <div class="cart-delivery bg-primary p-2 d-flex align-content-start">
            <!-- icon -->
            <b-icon-truck variant="secondary" font-scale="2" class="ml-2 mr-3"></b-icon-truck>
            <!-- premises -->
            <b-overlay :opacity="overlay.opacity" :show="overlay.premisesVisible" rounded="sm" spinner-small
                       spinner-variant="secondary">
                <b-form-select
                    id="cart.deliveryPremises"
                    v-model="record.premises_id"
                    :options="getPremises"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    size="sm"
                    :title="$t('cart.choose_delivery_place_tooltip')"
                    style="max-width: 180px"
                    @change="premisesChange"
                ></b-form-select>
            </b-overlay>
            <!-- date -->
            <b-overlay :opacity="overlay.opacity" :show="overlay.dateVisible" rounded="sm" spinner-small
                       spinner-variant="secondary">
                <b-form-select
                    id="cart.deliveryDate"
                    v-model="record.date"
                    :options="getDates"
                    :disabled="!record.premises_id > 0"
                    class="mb-2 ml-2 mb-sm-0"
                    size="sm"
                    :title="$t('cart.choose_delivery_date_tooltip')"
                    style="max-width: 200px"
                    @change="dateChange()"
                ></b-form-select>
            </b-overlay>
            <!-- time -->
            <b-overlay :opacity="overlay.opacity" :show="overlay.timeVisible" rounded="sm" spinner-small
                       spinner-variant="secondary">
                <b-form-select
                    id="cart.deliveryTime"
                    v-model="record.time"
                    :options="getTime"
                    :disabled="record.date === null"
                    class="mb-2 mr-sm-2 mb-sm-0 ml-3"
                    size="sm"
                    :title="$t('cart.choose_delivery_time_tooltip')"
                    style="max-width: 120px"
                    @change="timeChange()"
                ></b-form-select>
            </b-overlay>
            <!-- delivery detail -->
            <b-button
                v-b-toggle.collapse-premises_info
                variant="info"
                v-text="'?'"
                class="sm ml-4 m-1"
                style="padding-top: 0.1rem; width: 26px; height: 26px"
                size="sm"
                :disabled="!record.premises_id > 0"
            ></b-button>
            <!-- title -->
            <h5 class="ml-auto mt-1 text-black-50 d-none d-lg-block">{{$t('cart.delivery_options')}}</h5>

            <!-- button refresh -->
<!--            <b-button-->
<!--            class="ml-2"-->
<!--            @click="eventRefresh()"-->
<!--            >refresh</b-button>-->
        </div>

        <!-- collapse: premises info -->
        <b-collapse id="collapse-premises_info">
            <b-card class="bg-info __help_message">
                <div v-html="getPremisesInfo"></div>
            </b-card>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: "DeliveryOptionToolbar",
    components: {},
    props: {
        uri: {
            type: Object,
            default: {}
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            overlay: {
                visible: false,
                premisesVisible: false,
                dateVisible: false,
                timeVisible: false,
                opacity: 0.7,
            },
        }
    },
    mounted() {
    },
    methods: {
        // <-- overlay -->
        showOverlay(show) {
            this.overlay.visible = show;
        },
        showOverlay_premises(show) {
            this.overlay.premisesVisible = show;
        },
        showOverlay_date(show) {
            this.overlay.dateVisible = show;
        },
        showOverlay_time(show) {
            this.overlay.timeVisible = show;
        },
        // <-- events -->
        eventRefresh(){
            this.$emit('eventRefresh');
        },
        // <-- changed -->
        premisesChange() {
            this.showOverlay_premises(true);
            this.record.delivery_option_id = null;
            this.record.date = null;
            this.record.time = null;
            // <--  -->
            this.deliveryUpdate();
        },
        dateChange() {
            this.showOverlay_date(true);
            this.record.delivery_option_id = null;
            this.record.time = null;
            // <--  -->
            this.deliveryUpdate();
        },
        timeChange() {
            this.showOverlay_time(true);
            // <-- delivery_option_id -->
            this.record.delivery_option_id = null;
            this.getTime.every(v => {
                if (v.value === this.record.time) {
                    this.record.delivery_option_id = v.delivery_option_id;
                    return false;
                }
                return true;
            });
            // <--  -->
            this.deliveryUpdate();
        },
        // <-- axios -->
        deliveryUpdate() {
            // <-- data -->
            let data = this.record;
            // <-- axios -->
            this.axios.put(this.uri.main + `/${data.id}`, data, this.__config)
                .then((response) => {
                    // <-- record -->
                    this.$set(this.record, 'id', response.data.id);
                    this.$set(this.record, 'premises_id', response.data.premises_id);
                    this.$set(this.record, 'date', response.data.date);
                    this.$set(this.record, 'time', response.data.time);
                    // <-- overlay -->
                    this.showOverlay_premises(false);
                    this.showOverlay_date(false);
                    this.showOverlay_time(false);
                })
                .catch((error) => {
                    // error = error.record.data;
                    // this.errors.message = error;
                    // this.showOverlay(false);
                });
        }
    },
    computed: {
        getPremises() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null,
                    delivery_option_id: null,
                }
            ];
            // <--  -->
            let items = this.data;
            items.forEach(item => {
                array.push({
                    text: item.name,
                    value: item.id,
                });
            });
            // <--  -->
            return array;
        },
        getDates() {
            let date_array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null,
                    delivery_option_id: null,
                }
            ];
            // <--  -->
            if (!this.record.premises_id > 0) {
                return date_array
            }
            // <--  -->
            let date;
            let items = this.data.find((n) => {
                if (n.id === this.record.premises_id) {
                    return n;
                }
            }).premises_delivery_options;
            // <-- date -->
            items.forEach(item => {
                date = item.content.delivery.date;
                let dayOfWeek = new Date(date).getDay();
                date_array.push({
                    text: this.$t('date_time.days_of_week_name.' + dayOfWeek) + ', ' + this.__convertBaseDateToLocaleDateString(date),
                    value: date,
                });
            });
            // <--  -->
            return date_array;
        },
        getTime() {
            let time_array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null,
                    delivery_option_id: null,
                }
            ];
            // <--  -->
            if (this.record.date === null) {
                return time_array;
            }
            // <-- premises delivery -->
            let time;
            let items = this.data.find((n) => {
                if (n.id === this.record.premises_id) {
                    return n;
                }
            }).premises_delivery_options;
            // <-- date -->
            items.forEach(item => {
                time = item.content.delivery.time;
                time_array.push({
                    text: time,
                    value: time,
                    delivery_option_id: item.id,
                });
            });
            // <--  -->
            return time_array;
        },
        getPremisesInfo() {
            let string = null;
            // <--  -->
            this.data.every((n) => {
                if (n.id === this.record.premises_id) {
                    string = n.locale.notes;
                    return false;
                }
                return true;
            });
            // <--  -->
            return string;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
