<template>
    <div class="p-3">
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <!-- title -->
            <b-input-group-append>
                <h4>{{$t('cart.cart')}}</h4>
                <label class="ml-2 pt-1">({{records.length}})</label>
            </b-input-group-append>
            <!-- SubmitOrderSuccessfullyMessage -->
            <div v-if="showSubmitOrderSuccessfullyMessage" class="mb-2">
                {{$t('cart.submitOrderSuccessfullyMessage')}}
                <p class="mt-1 font-weight-bolder">{{$t('form.check_spam_message')}}</p>
            </div>
            <!-- no hits -->
            <div v-if="records.length === 0">
                {{$t('cart.no_hits_message')}} {{$t('cart.noLogin_message')}}
            </div>
            <div v-else>
                <!-- delivery toolbar -->
                <delivery-option-toolbar
                    :uri="uri"
                    :data="premises"
                    :record="deliveryOption"
                    @eventRefresh="baseSelect()"
                ></delivery-option-toolbar>
                <!--  -->
                <b-row>
                    <!-- left part: cart products -->
                    <b-col cols="12" md="12" lg="12" xl="8" class="mb-3">
                        <div
                            v-for="record in __getPaginationArrayItems(records, $root.mx_pagination.perPage, currentPage)">
                            <cart-product
                                :uri="uri"
                                :record="record"
                                @eventRemove="removeProduct"
                            ></cart-product>

                        </div>
                    </b-col>
                    <!-- right part -->
                    <b-col cols="12" md="12" lg="12" xl="4">
                        <b-card class="mb-2">
                            <!-- sub total -->
                            <div class="d-flex justify-content-between font-weight-bold">
                                <b-card-text>{{$t('cart.subtotal')}}:</b-card-text>
                                <b-card-text>{{getSubtotalPrice.string}}</b-card-text>
                            </div>
                            <!-- delivery -->
                            <div class="d-flex justify-content-between mb-0">
                                <b-card-text>{{$t('cart.delivery')}}:</b-card-text>
                                <b-card-text>{{getDeliveryPrice.string}}</b-card-text>
                            </div>
                            <!-- tax -->
                            <div v-if="__getTenantCookieValue.taxpayer" class="d-flex justify-content-between mb-0">
                                <b-card-text>{{$t('cart.tax')}}:</b-card-text>
                                <b-card-text>0,00 € ???</b-card-text>
                            </div>
                            <hr class="mt-1">
                            <!-- total -->
                            <div class="d-flex justify-content-between mt-4">
                                <b-card-text class="h5">{{$t('cart.total')}}:</b-card-text>
                                <b-card-text class="font-weight-bold h5">{{getTotalPrice.string}}</b-card-text>
                            </div>
                        </b-card>
                        <!-- collapse: cheaperDelivery message -->
                        <b-collapse id="collapse-cheaperDelivery" :visible="getCheaperDeliveryVisible" class="mt-0">
                            <b-card class="bg-info">
                                <div v-if="getDeliveryPrice.cp === 0"
                                     v-html="$t('trade.delivery_price.free_delivery_message', {missing: getDeliveryPrice.missing_string})"></div>
                                <div v-else
                                     v-html="$t('trade.delivery_price.cheaper_delivery_message', {cp: getDeliveryPrice.cp_string, missing: getDeliveryPrice.missing_string})"></div>
                            </b-card>
                        </b-collapse>
                        <!-- collapse: error message -->
                        <b-collapse id="collapse-Error-message" :visible="getErrorMessage !== null" class="mt-0">
                            <b-card class="mt-2 alert-danger">
                                <div v-html="getErrorMessage"></div>
                            </b-card>
                        </b-collapse>
                        <!-- confirm purchase -->
                        <b-button
                            size="lg"
                            class="shadow w-100 mt-2"
                            variant="primary"
                            :disabled="isSubmitOrderDisabled"
                            @click="submitOrder()"
                        >
                            {{$t('cart.confirm')}}
                        </b-button>
                        <!-- disclaimer -->
                        <b-card
                            class="shadow mb-3"
                            title=""
                        >
                            <div class="d-flex justify-content-center">
                                <b-card-text>{{$t('cart.disclaimer')}}</b-card-text>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
                <!-- pagination -->
                <b-pagination
                    v-model="currentPage"
                    :per-page="$root.mx_pagination.perPage"
                    :total-rows="records.length"
                    limit="20"
                    size="sm"
                ></b-pagination>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import CartProduct from "@/components/_public/cart/CartProduct";
import DeliveryOptionToolbar from "@/components/_public/cart/DeliveryOptionToolbar";

export default {
    name: "Cart",
    components: {DeliveryOptionToolbar, CartProduct},
    props: {},
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            uri: null,
            cart_id: 0,
            records: [],
            currentPage: 1,
            deliveryPrices: [],
            premises: [],
            deliveryOption: {
                id: 0,
                delivery_option_id: null,
                premises_id: null,
                date: null,
                time: null,
            },
            showingProductCount: 0,
            errorMessage: null,
            showSubmitOrderSuccessfullyMessage: false,
        }
    },
    mounted() {
        this.showSubmitOrderSuccessfullyMessage = false;
        this.uri = this.$root.mx_cartUri;
        this.$root.mx_pagination.visible = true;
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        clearCartData(){
            // <-- cart_id -->
            this.cart_id = null;
            // <-- records -->
            this.$set(this, 'records', []);
            // <-- cartCount -->
            this.$root.mx_cartCount = 0;
            // <-- delivery prices -->
            this.$set(this, 'deliveryPrices', []);
            // <-- premises -->
            this.$set(this, 'premises', []);
            // <-- delivery_options -->
            let deliveryOption = {
                id: 0,
                delivery_option_id: null,
                premises_id: null,
                date: null,
                time: null,
            };
            this.$set(this, 'deliveryOption', deliveryOption);
        },
        // <-- events -->
        removeProduct(event) {
            this.showingProductCount = 0;
            this.baseDelete(event)
        },
        // <-- api calls -->
        baseSelect: function () {
            this.showOverlay(true);
            // <-- axios -->
            this.axios.get(this.uri.main, this.__config)
                .then((response) => {
                    if (response.data.cartCount === 0) {
                        this.clearCartData();
                        this.showOverlay(false);
                        return;
                    }
                    // <-- cart_id -->
                    this.cart_id = response.data.records.id;
                    // <-- records -->
                    this.$set(this, 'records', response.data.records.cart_content);
                    // <-- cartCount -->
                    this.$root.mx_cartCount = response.data.cartCount;
                    // <-- delivery prices -->
                    this.$set(this, 'deliveryPrices', response.data.deliveryPrices);
                    // <-- premises -->
                    this.$set(this, 'premises', response.data.premises);
                    // <-- delivery_options -->
                    this.$set(this.deliveryOption, 'id', response.data.records.id);
                    this.$set(this.deliveryOption, 'delivery_option_id', response.data.records.delivery_option_id);
                    this.$set(this.deliveryOption, 'premises_id', response.data.records.premises_id);
                    this.$set(this.deliveryOption, 'date', response.data.records.date);
                    this.$set(this.deliveryOption, 'time', response.data.records.time);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                    this.showOverlay(false);
                });
        },
        baseDelete(record_id) {
            this.showOverlay(true);
            // <-- axios -->
            this.axios.delete(this.uri.main + `/${record_id}`, this.__config)
                .then((response) => {
                    this.$set(this, 'records', response.data.records.cart_content);
                    // <-- cartCount -->
                    this.$root.mx_cartCount = response.data.cartCount;
                    // <-- delivery prices -->
                    this.$set(this, 'deliveryPrices', response.data.deliveryPrices);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        submitOrder() {
            this.showOverlay(true);
            this.$set(this, 'errorMessage', null);
            // <-- data -->
            let data = {
                cart_id: this.cart_id,
                delivery_option_id: this.deliveryOption.delivery_option_id
            }
            // <-- axios -->
            this.axios.post(this.uri.uri1 + `/submit_order`, data, this.__config)
                .then((response) => {
                    if (response.data.cartCount === 0) {
                        this.clearCartData();
                        this.showSubmitOrderSuccessfullyMessage = true;
                    }
                    this.showOverlay(false);
                })
                .catch((error) => {
                    this.$set(this, 'errorMessage', error.response.data);
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        }
    },
    computed: {
        getCheaperDeliveryVisible() {
            if (this.getDeliveryPrice.missing !== undefined) {
                if (this.showingProductCount < this.records.length) {
                    this.showingProductCount++;
                }
            }
            // <--  -->
            return this.showingProductCount >= this.records.length && this.getDeliveryPrice.missing > 0;
        },
        // <-- calculating -->
        getSubtotalPrice() {
            let subTotal = 0;
            if (this.records.length === 0) {
                return {
                    value: 0,
                    string: null
                };
            }
            // <--  -->
            this.records.forEach(record => {
                subTotal += record.sumPrice === undefined ? 0 : record.sumPrice;
            });
            // <--  -->
            return {
                value: subTotal,
                string: this.__getCurrencyLocale(subTotal)
            };
        },
        getDeliveryPrice() {
            let range = null;
            if (this.__isNull(this.deliveryPrices) || this.getSubtotalPrice.value === 0) {
                return {
                    value: 0,
                    string: this.$t('trade.delivery_price.free')
                };
            }
            // <--  -->
            let deliveryPrices = this.deliveryPrices.sort((t1, t2) => t1.price < t2.price ? -1 : 1);
            // <--  -->
            let stp = this.getSubtotalPrice.value;
            let price = null;
            let index = 0;
            deliveryPrices.forEach((dp, dpIndex) => {
                price = price === null ? dp.price : price;
                if (stp < dp.range) {
                    price = dp.price;
                    index = dpIndex;
                }
            });
            // <-- cheaper delivery -->
            let cp = 0;
            let missing = 0;
            if (index > 0) {
                cp = deliveryPrices[index - 1].price;
                missing = deliveryPrices[index].range - stp;
            }
            // <--  -->
            return {
                value: price,
                string: price === 0 ? this.$t('trade.delivery_price.free') : this.__getCurrencyLocale(price),
                cp: cp,
                cp_string: this.__getCurrencyLocale(cp),
                missing: missing,
                missing_string: this.__getCurrencyLocale(missing),
            };
        },
        getTotalPrice() {
            let price = this.getSubtotalPrice.value;
            price += this.getDeliveryPrice.value;
            // <--  -->
            return {
                value: price,
                string: this.__getCurrencyLocale(price)
            };
        },
        getErrorMessage() {
            // <-- Delivery is not selected -->
            if (this.deliveryOption.time === null) {
                return this.$t('cart.delivery_error_message');
            }
            // <-- response error -->
            if (this.errorMessage !== null) {
                return this.errorMessage.message;
            }
            // <--  -->
            return null;
        },
        isSubmitOrderDisabled() {
            let enabled = this.deliveryOption.delivery_option_id > 0
                && this.deliveryOption.premises_id > 0
                && this.deliveryOption.date !== null
                && this.deliveryOption.time !== null;
            // <--  -->
            return !enabled;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
